/* eslint-disable @typescript-eslint/no-empty-interface */

import {
    Arm,
    Borrower,
    Closing,
    Product,
    Property,
    Terms,
    Fee,
    Gfe,
    MortgageInsurance,
    PrepaymentPenalty,
    Seller,
    Trust,
    Investor,
    TitleCompany,
    SettlementAgent,
    Underwriting,
    Vesting,
    Escrow,
    Lender,
    Broker,
    DocumentDatasets,
    AutomatedUnderwriting,
    CalculatedData,
    LoanOptions,
    DeedOfTrust,
    MersInfo,
    LateFee,
    Fha,
    ExistingLoan,
    Va,
    PropertyType,
    StateSpecific,
    Cd,
    ServiceProvider,
    TangibleNetBenefits,
    DocumentConfig,
    LoanHeloc,
    Funding,
    Address,
    Overridable,
    ComplianceOnlyData,
    HousingExpense,
    AuditInfo,
    Originator,
    Le,
    MultiSignerNotary,
    TransactionDetailType,
    ComplianceTestResult,
    TridDisclosure,
    LoanRequestOptions,
    ContractorSignerNotary
} from './index'
import { AuditCheckResult, DocumentMode } from '@/propel/models'

export interface LoanRecord {
    id: string
    clientCode: string
    loanNumber: string
    documentMode: string
    audit: AuditInfo
    data: Loan
    options: LoanOptions
    calculations: CalculatedData
    cd: Cd
    lock: Lock | null
    schemaVersion: string
    partnerCode: string
    le: Le
    defaultedFields: Record<string, LoanDefaultValueResult>
    persistedId: string
    originatingSystem: string
    complianceResults: { results: ComplianceTestResult[] }
}

export interface LoanSummary {
    id: string
    clientCode: string
    description: string
    loanNumber: string
    lenderCaseNumber: string
    documentMode: string
    primaryBorrower: string
    programName: string
    property: LoanSummaryAddress
    audit: AuditInfo
    closingDate: Date
    importError: any
    transactionDetail: TransactionDetailType
}

export interface LoanSummaryAddress {
    street: string
    city: string
    state: string
    propertyType: PropertyType[]
}

export interface Loan {
    summaryDescription: string
    property: Property
    arm: Arm
    closing: Closing
    escrow: Escrow
    housingExpenses: HousingExpense[]
    product: Product
    mortgageInsurance: MortgageInsurance
    prepaymentPenalty: PrepaymentPenalty
    terms: Terms
    primaryBorrower: PrimaryBorrower
    broker: Broker
    settlementAgent: Overridable<SettlementAgent>
    investor: Investor
    titleCompany: TitleCompany
    notary: MultiSignerNotary
    servicer: Servicer
    processor: Processor
    deedOfTrust: DeedOfTrust
    originator: Originator
    gfe: Gfe
    borrowers: Borrower[]
    contractors: Contractor[]
    fees: Fee[]
    documentDatasets: DocumentDatasets
    serviceProviders: ServiceProvider[]
    construction: Construction
    seller: Seller
    trusts: Trust[]
    buydowns: Buydown[]
    underwriting: Underwriting
    vesting: Vesting
    lender: Lender
    automatedUnderwriting: AutomatedUnderwriting
    mersInfo: MersInfo
    lateFee: LateFee
    fha: Fha
    va: Va
    tangibleNetBenefits: TangibleNetBenefits
    existingLoan: ExistingLoan
    buydownChangeFrequency: number
    hudInterestCredits: number
    cashOut: number
    vaFhaClosingCosts: number
    heloc: LoanHeloc
    funding: Funding
    mersPurchaseLineNumber: string
    vhapLoanNumber: string
    secondLoanAmount: number
    mersMin: string
    universalLoanIdentifier: string
    lenderCaseNumber: string
    hasMersRegistration: boolean
    oldLoanPrincipalAndInterest: number
    principalAndInterestOverride: number
    firstPaymentDate: Date
    earnestMoney: number
    isRefinanceCashOut: boolean
    applicationDate: Date
    lenderPayToLicense: string
    branchNumber: string
    financialAdvisor: string
    recordingDocumentLocation: string
    otherRiderDescription: string
    olD_LOAN_TERM: number
    vhaP_TERM: number
    vhaP_LOAN_TYPE: string
    //originalDisclosureDate: Date
    isBrokerOriginated: boolean
    documentExpirationDate: Overridable<Date>
    cltv: number
    stateSpecific: StateSpecific
    isInActiveForeclosure: boolean
    includeOnlyBorrowerFeesLe: boolean
    excludeEmptyFeesFromCD: boolean
    documentOptions: DocumentConfig
    overriddenDefaultPaths: Record<string, boolean>
    temp: Record<string, any>
    complianceOnlyData: ComplianceOnlyData
    clientCustomFields: Record<string, string>
    tridDisclosures: TridDisclosure[]
    calculations: CalculatedData // will only be populated in the context of trid disclosures
}

export interface LoanLockRequest {
    lock: boolean
    force: boolean | null
}

export interface LoanPersistRequest extends LoanRequestOptions {
    requestCachedId: boolean
    updateLoanRecord: boolean | null
}

export interface LoanAuditChecks {
    [jsonPathKey: string]: AuditCheckResult[]
}

export interface Lock {
    user: string
    startTime: Date
    updateTime: Date
    exclusiveOwner: string
}

export interface LoanRecordDownLoad {
    id: string
    clientCode: string
    loanNumber: string
    documentMode: string
    audit: AuditInfo | undefined
    data: Loan
    schemaVersion: string
    originatingSystem: string
}

export interface ImportError {
    correlationId: string
    error: string
}

// todo: placeholders until actually needed in component
export interface PrimaryBorrower { }
export interface Servicer { }
export interface Processor { }
export interface Buydown { }


export interface Construction {
    interimRate: number
 }

export interface Contractor { 
    name: string
    phone: string
    fax: string
    emailAddress: string
    address: Address
    entityType: string
    typeOtherDescription: string
    taxId: string
    licenseNumber: string
    notary: ContractorSignerNotary
    contractDate: Date
    completionDate: Date
    contractNoteAmount: number
    contractCashPortion: number
    retainage: number
    escrowHoldbackAmount: number
    vaIdentification: string
    constructionTerm: number
    selfHelp203k: boolean
    renovationPayLiens: boolean
}

export enum LoanPurpose {
    Purchase = "Purchase",
    Refinance = "Refinance",
    Modification = "Modification",
    Assumption = "Assumption"
}

export interface LoanDefaultValueResult {
    jsonPath: string
    value: any
    preventChange: boolean
    replaceExistingData: boolean
    currentlyDefault: boolean
    fieldId: string
}

export interface ClientError {
    userName: string
    info: string
    stack: string | undefined
}

export interface XmlParseResult {
    loanNumber: string
    loan: Loan
}

export enum DocumentModeConversionType {
    MatchAllEstimatedAmounts = 'MatchAllEstimatedAmounts',
    DoNotMatchAmounts = 'DoNotMatchAmounts'
}

export interface UpdateDocumentModeRequest {
    documentMode: DocumentMode
    matchEstimatedAmountsOption: string
}