export interface PackageDetails {
    fileId: string
    delivery: PackageDelivery
}

export interface PackageDelivery {
    email: PackageEmail
}

export interface PackageEmail {
    templateId: string
    from: PackageEmailDetails
    to: string[]
    security: PackageSecurityOptions
}

export interface PackageSecurityOptions {
    expiration: number
    systemPassword: boolean
    loanDetails: PackageLoanDetails
}

export interface PackageLoanDetails {
    loanNumber: string
    propertyStreetNumber: string
    propertyZipCode: string
    borrowerSSN: string
    borrowerBirthDate: string
}

export interface PackageEmailDetails {
    displayName: string
    emailAddress: string
}

export enum ClientDeliveryOption {
    Save = "Save",
    LoanReview = "LoanReview",
    DocuSign = "DocuSign",
    NotaryCam = "NotaryCam",
    Email = "Email",
    SaveDraft = "SaveDraft"
}

export interface NewPackageSaveOption {
    displayText: string
    description: string
    value: ClientDeliveryOption
    buttonText: string
    draftLabelText: string
}